import _ from "lodash";
import * as lua from "../../../vendor/lua-in-js";
import { Module, modules } from "../data/modules";
import { get } from "../mission";
import { LuaJobFunc } from "../models/Job";
import { PlanLike, combinePlans } from "../models/Plan";
import { overridesToPresets, setRadioPresetForGroup } from "../radios";
import { DCSWaypoint, LuaTable } from "../types";

export function makePoint(wp: DCSWaypoint): lua.Table {
  const luaPoint = new lua.Table();

  _.each(_.omit(wp, "number", "name"), (v, k) => {
    luaPoint.set(k, v);
  });

  if (wp.name !== wp.number.toString()) {
    luaPoint.set("name", wp.name);
  }

  luaPoint.set("x", wp.x);
  luaPoint.set("y", wp.y);
  luaPoint.set("alt", wp.alt || 0);
  luaPoint.set("alt_type", wp.alt_type || "RADIO");
  luaPoint.set("ETA", wp.ETA);
  luaPoint.set("ETA_locked", wp.ETA ? true : false);
  luaPoint.set("speed", wp.speed || 138);
  luaPoint.set("speed_locked", wp.ETA ? false : true);
  const task = new lua.Table();
  task.set("id", "ComboTask");
  const params = new lua.Table();
  params.set("tasks", new lua.Table());
  task.set("params", params);
  luaPoint.set("task", task);
  luaPoint.set("formation_template", "");
  luaPoint.set("action", wp.type);

  return luaPoint;
}

export const applyPlans = (plans: PlanLike[]): LuaJobFunc => (
  mission: LuaTable
) => {
  let updated = mission;

  const merged = combinePlans(plans);

  for (const plan of merged) {
    const countries = get(updated, ["coalition", plan.coalition, "country"]);

    for (const country of countries.numValues) {
      if (!country) {
        continue;
      }

      const cat = country.strValues[plan.groupCategory]?.strValues.group;

      if (cat) {
        for (const group of cat.numValues) {
          if (!group) {
            continue;
          }

          const match = group.strValues.name === plan.groupName;

          if (match) {
            if (plan.radioOverrides) {
              const ovr = JSON.parse(plan.radioOverrides);
              setRadioPresetForGroup(
                plan.coalition,
                plan.groupCategory,
                plan.groupName,
                overridesToPresets(ovr)
              )(mission);
            }

            if (plan.waypoints && plan.waypoints.length > 0) {
              for (const wp of plan.waypoints) {
                const idx = wp.number + 1;

                if (idx === 1) {
                  continue;
                }

                const tbl = makePoint(wp);
                group.strValues.route.strValues.points.numValues[idx] = tbl;
              }
            }

            if (plan.laserConfig) {
              const units = group.strValues.units.numValues;

              for (const unit of units) {
                if (!unit) {
                  continue;
                }
                const index = units.indexOf(unit);

                const m = modules[unit.strValues.type] as Module;

                if (!m || !m.laserConfigFields) {
                  continue;
                }

                const formatter = m.mizLaserFormatter;
                const laserEntry = plan.laserConfig[index - 1];

                if (!formatter || !laserEntry) {
                  continue;
                }

                const props = unit.strValues.AddPropAircraft.strValues;
                try {
                  formatter(props, laserEntry);
                } catch (e) {
                  console.error(
                    `Failed to apply laser config for ${unit.strValues.type}: ${e}`
                  );
                }
              }
            }

            if (plan.navPoints && plan.navPoints.length > 0) {
              const tbl =
                group.strValues.NavTargetPoints?.numValues ||
                new lua.Table().numValues;

              for (const np of plan.navPoints) {
                const t = new lua.Table();
                t.set("x", np.x);
                t.set("y", np.y);
                t.set("text_comment", np.textComment);
                t.set("index", np.index);
                tbl[np.index] = t;
              }

              if (!group.strValues.NavTargetPoints) {
                group.strValues.NavTargetPoints = new lua.Table();
                group.strValues.NavTargetPoints.numValues = tbl;
              }
            }
          }
        }
      }
    }
  }
};
